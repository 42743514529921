import axios from 'axios';
import { toast } from 'react-toastify';

import { userStoreInstance } from '@/features/Auth/store';

export const baseURL = import.meta.env.VITE_EVENTS_URL || 'http://localhost:3000/api/v2/skdf';

const client = axios.create({ baseURL });

// Request interceptor for API calls
client.interceptors.request.use(
  async (config) => {
    const { user } = userStoreInstance;
    if (user && user !== 'anonymous') {
      config.headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      config.baseURL = baseURL;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
client.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if ([403, 401].includes(error.response.status)) {
      if (originalRequest._retry) {
        await userStoreInstance.removeUser();
        window.location.reload();
      } else {
        originalRequest._retry = true;
        await userStoreInstance.renewToken().catch(async () => {
          await userStoreInstance.removeUser();
          window.location.reload();
        });
        const { user } = userStoreInstance;
        if (user && user !== 'anonymous') {
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
          return client(originalRequest);
        }
      }
    }

    if (error.response.status === 405) {
      toast.error('У вас недостаточно прав для этого действия', { autoClose: false });
      return;
    }

    return Promise.reject(error);
  }
);

if (import.meta.env.DEV) {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let message = String(error);
      if (axios.isAxiosError(error)) message = error.message;

      const customError = error?.response?.data?.error?.message;

      toast.error(customError || message, { autoClose: false });
      return Promise.reject(error);
    }
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['httpPlanningClient'] = client;
} else if (import.meta.env.PROD) {
  client.interceptors.response.use(undefined, (error) => {
    const customError = error?.response?.data?.error?.message;

    toast.error(customError || 'Произошла ошибка при загрузке данных', { autoClose: false });
    return Promise.reject(error);
  });
}

export default client;
