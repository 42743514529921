import React, { lazy, ReactNode, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles/bs.scss';
import './styles/bs_skdf.scss';

import ToastifyContainer from './components/ToastifyContainer';
import Sidebar from './features/Sidebar';
import sidebarClasses from './features/Sidebar/Sidebar.module.scss';
import ProtectedRoute from './features/AccessMatrix/ProtectedRoute';
import AuthWrapper from './features/Auth/AuthWrapper';
import Spinner from './components/Spinner';
import MultiStepModal from './features/MultiStepModal';
import { FeatureFlags } from '@/features/FeatureFlags';
import Page404 from './pages/Page404';

const SkdfDesign = lazy(() => import('./SkdfDesign'));
const CallbackPage = lazy(() => import('./features/Auth/CallbackPage'));

const MainPage = lazy(() => import('./pages/MainPage'));

const AccountPage = lazy(() => import('./pages/AccountPage'));
const RoadsPage = lazy(() => import('./pages/RoadsPage'));
const RoadsPageSeparated = lazy(() => import('./pages/RoadsPageSeparated'));
const RoadPage = lazy(() => import('./pages/RoadPage'));
const RoadOnBalancePage = lazy(() => import('./pages/RoadOnBalancePage'));
const BackboneNetworkPage = lazy(() => import('./pages/BackboneNetworkPage'));
const RoadDiagnosticsPage = lazy(() => import('./pages/RoadDiagnosticsPage'));
const AccidentsPage = lazy(() => import('./pages/AccidentsPage'));
const AccidentPage = lazy(() => import('./pages/AccidentPage'));
const BridgesPage = lazy(() => import('./pages/BridgesPage'));
const BridgePage = lazy(() => import('./pages/BridgePage'));

// HWM
const RoadPageHWM = lazy(() => import('./pages/HWM/RoadPageHWM'));
const RoadOnBalanceHWM = lazy(() => import('./pages/HWM/RoadOnBalanceHWM'));
const BridgePageHWM = lazy(() => import('./pages/HWM/BridgePageHWM'));

const NewsletterPage = lazy(() => import('./pages/NewsletterPage'));
const ProcurementPlansPage = lazy(() => import('./pages/ProcurementPlansPage'));
const ProcurementPlanPage = lazy(() => import('./pages/ProcurementPlanPage'));
const PlanSchedulesPage = lazy(() => import('./pages/PlanSchedulesPage'));
const PlanSchedulePage = lazy(() => import('./pages/PlanSchedulePage'));
const ContractsPage = lazy(() => import('./pages/ContractsPage'));
const ContractPage = lazy(() => import('./pages/ContractPage'));
const PurchNoticesPage = lazy(() => import('./pages/PurchNoticesPage'));
const PurchNoticePage = lazy(() => import('./pages/PurchNoticePage'));
const MessagesPage = lazy(() => import('./pages/MessagesPage'));
const HelpPage = lazy(() => import('./pages/HelpPage'));
const MessagePage = lazy(() => import('./pages/MessagePage'));
const ContactsPage = lazy(() => import('./pages/ContactsPage'));
const AdministrationPage = lazy(() => import('./administration'));
const RoadOwnersPage = lazy(() => import('./pages/RoadOwnersPage'));
const Statistics = lazy(() => import('./stats'));

const Desktop = lazy(() => import('./pages/Desktop'));
const FramePage = lazy(() => import('./pages/FramePage'));

const Planning = lazy(() => import('./planning'));
const NotificationsPage = lazy(() => import('./notifications'));

const OrganizationPage = lazy(() => import('./pages/OrganizationPage'));

const MapPage = lazy(() => import('./map'));

const StatForm = lazy(() => import('./StatForm'));

const MapGraph = lazy(() => import('./MapGraph'));
const MapRouting = lazy(() => import('./MapValhalla'));

const ConstructionBridge = lazy(() => import('./construction/bridges/BridgePage'));
const ConstructionBridgesList = lazy(() => import('./construction/bridges/BridgesList'));
const ConstructionRoad = lazy(() => import('./construction/roads/RoadPage'));
const ConstructionRoadsList = lazy(() => import('./construction/roads/RoadsList'));
const MapEditGeom = lazy(() => import('./construction/roads/EditGeom'));

const AppLayout = () => (
  <>
    <Sidebar />
    <div className={sidebarClasses.sidebarContent}>
      <Outlet />
    </div>
  </>
);

function CenterSpinner() {
  return <Spinner size={48} className="position-absolute top-50 start-50 translate-middle text-primary" />;
}

export function suspense(children: ReactNode) {
  return <Suspense fallback={<CenterSpinner />} children={children} />;
}

function App() {
  const { features } = useContext(FeatureFlags);

  return (
    <div className="App">
      <AuthWrapper>
        <Router>
          <Routes>
            <Route path="/callback" element={suspense(<CallbackPage />)} />

            <Route path="/" element={suspense(<MainPage />)} />

            <Route element={<AppLayout />}>
              <Route path="/skdf-design/*" element={suspense(<SkdfDesign />)} />
              <Route
                path="/account"
                element={<ProtectedRoute matrixKey="menu_account_page" element={suspense(<AccountPage />)} />}
              />
              <Route
                path="/roads"
                element={
                  <ProtectedRoute
                    matrixKey="menu_roads"
                    element={suspense(features.isSeparatedRoadsPageEnabled ? <RoadsPageSeparated /> : <RoadsPage />)}
                  />
                }
              />
              <Route
                path="/roadsOld"
                element={<ProtectedRoute matrixKey="menu_roads" element={suspense(<RoadsPage />)} />}
              />
              <Route
                path="/roads/construction"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<ConstructionRoadsList />)} />}
              />
              <Route
                path="/roads/construction/:id/edit-geom"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<MapEditGeom />)} />}
              />
              <Route
                path="/roads/construction/:id"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<ConstructionRoad />)} />}
              />
              <Route
                path="/roads/:id"
                element={<ProtectedRoute matrixKey="menu_roads" element={suspense(<RoadPage />)} />}
              />
              <Route
                path="/roads/hwm/:id"
                element={<ProtectedRoute matrixKey="menu_roads" element={suspense(<RoadPageHWM />)} />}
              />
              <Route
                path="/roadOnBalance/hwm/:id"
                element={<ProtectedRoute matrixKey="menu_roads" element={suspense(<RoadOnBalanceHWM />)} />}
              />
              <Route path="/roadOnBalance/:id" element={suspense(<RoadOnBalancePage />)} />
              <Route path="/backboneNetwork/:id" element={suspense(<BackboneNetworkPage />)} />
              <Route
                path="/roadDiagnostics/:id"
                element={
                  <ProtectedRoute matrixKey="menu_road_diagnostics" element={suspense(<RoadDiagnosticsPage />)} />
                }
              />
              <Route
                path="/traffic-accidents"
                element={<ProtectedRoute matrixKey="menu_accidents" element={suspense(<AccidentsPage />)} />}
              />
              <Route
                path="/traffic-accidents/:id"
                element={<ProtectedRoute matrixKey="menu_accidents" element={suspense(<AccidentPage />)} />}
              />
              <Route
                path="/bridges"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<BridgesPage />)} />}
              />
              <Route
                path="/bridges/construction"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<ConstructionBridgesList />)} />}
              />
              <Route
                path="/bridges/construction/:id"
                element={<ProtectedRoute matrixKey="menu_bridges" element={suspense(<ConstructionBridge />)} />}
              />
              <Route path="/bridges/:id" element={suspense(<BridgePage />)} />
              <Route path="/bridges/hwm/:id" element={suspense(<BridgePageHWM />)} />

              <Route
                path="/notifications"
                element={<ProtectedRoute matrixKey="menu_notifications" element={suspense(<NotificationsPage />)} />}
              />
              <Route
                path="/administration/newsletter"
                element={<ProtectedRoute matrixKey="menu_newsletter" element={suspense(<NewsletterPage />)} />}
              />
              <Route
                path="/purchNotices"
                element={<ProtectedRoute matrixKey="menu_purchase_notices" element={suspense(<PurchNoticesPage />)} />}
              />
              <Route
                path="/purchNotices/:id"
                element={<ProtectedRoute matrixKey="menu_purchase_notices" element={suspense(<PurchNoticePage />)} />}
              />
              <Route
                path="/procurementPlans"
                element={
                  <ProtectedRoute matrixKey="menu_procurement_plans" element={suspense(<ProcurementPlansPage />)} />
                }
              />
              <Route
                path="/procurementPlans/:id"
                element={
                  <ProtectedRoute matrixKey="menu_procurement_plans" element={suspense(<ProcurementPlanPage />)} />
                }
              />
              <Route
                path="/planSchedules"
                element={<ProtectedRoute matrixKey="menu_plan_schedules" element={suspense(<PlanSchedulesPage />)} />}
              />
              <Route
                path="/planSchedules/:id"
                element={<ProtectedRoute matrixKey="menu_plan_schedules" element={suspense(<PlanSchedulePage />)} />}
              />
              <Route
                path="/contracts"
                element={<ProtectedRoute matrixKey="menu_contracts" element={suspense(<ContractsPage />)} />}
              />
              <Route
                path="/contracts/:id"
                element={<ProtectedRoute matrixKey="menu_contracts" element={suspense(<ContractPage />)} />}
              />
              <Route
                path="/messages"
                element={<ProtectedRoute matrixKey="menu_citizen_messages" element={suspense(<MessagesPage />)} />}
              />
              <Route
                path="/messages/:id"
                element={<ProtectedRoute matrixKey="menu_citizen_messages" element={suspense(<MessagePage />)} />}
              />
              <Route
                path="/contacts"
                element={<ProtectedRoute matrixKey="menu_contacts" element={suspense(<ContactsPage />)} />}
              />

              <Route
                path={'/administration/*'}
                element={<ProtectedRoute matrixKey="menu_administration" element={suspense(<AdministrationPage />)} />}
              />
              <Route path="/help" element={<Navigate to="/help/info" replace />} />
              <Route path="/help/*" element={suspense(<HelpPage />)} />
              <Route
                path="/desktop"
                element={<ProtectedRoute matrixKey="menu_desktop" element={suspense(<Desktop />)} />}
              />
              <Route path="/indicators/:title" element={suspense(<FramePage />)} />
              <Route path="/dashboards/:title" element={suspense(<FramePage />)} />

              <Route
                path="/planning/*"
                element={<ProtectedRoute matrixKey="menu_events" element={suspense(<Planning />)} />}
              />
              <Route
                path="/contacts/org/:id"
                element={<ProtectedRoute matrixKey="menu_contacts" element={suspense(<OrganizationPage />)} />}
              />

              <Route
                path="/roadOwners"
                element={<ProtectedRoute matrixKey="menu_contacts" element={suspense(<RoadOwnersPage />)} />}
              />

              <Route path="/stats/*" element={suspense(<Statistics />)} />

              <Route path="/map/*" element={suspense(<MapPage />)} />

              <Route path="/map-graph" element={suspense(<MapGraph />)} />

              <Route path="/map-valhalla/:action_type" element={suspense(<MapRouting />)} />

              <Route path="/statforms/:okud" element={suspense(<StatForm />)} />
              <Route path="/statforms/:okud/:report_id" element={suspense(<StatForm />)} />
              <Route path="/statforms/:okud/:report_id/:chapter_id" element={suspense(<StatForm />)} />

              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
        </Router>
      </AuthWrapper>
      <ToastifyContainer />
      <MultiStepModal />
    </div>
  );
}

export default App;
