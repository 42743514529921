import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Log, type User, UserManager } from 'oidc-client-ts';

import { AccessMatrixStore } from '@/features/AccessMatrix/store';
import { userManagerSettings } from '@/userManagerSettings';
import PlanningAccess from '@/features/PlanningAccess/store';
import { type Account, fetchProfile, type FieldValue } from '../api';

class UserStore {
  #userManager: UserManager;
  accessMatrix = new AccessMatrixStore(this);
  planningAccess = new PlanningAccess();
  public user: User | 'anonymous' | null = null;
  public account: Account | null = null;

  fetchState: 'pending' | 'error' | 'done' = 'pending';

  constructor() {
    makeObservable(this, {
      fetchState: observable,
      user: observable,
      account: observable,

      init: action,
      getUser: action,
      setUser: action,
      renewToken: action,
      getAccountInfo: action,

      systemRoles: computed,
      userRolesMap: computed,
      userOrganizationsMap: computed,
      userRolesAndOrganizations: computed,
      isSignedIn: computed,
    });
    // User manager
    this.#userManager = new UserManager(userManagerSettings);
    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  get isSignedIn() {
    return this.user !== null && this.user !== 'anonymous' && this.user.profile.idp !== 'Esia';
  }

  login() {
    const { pathname, href, origin } = window.location;
    localStorage.setItem('afterSignInRedirect', pathname === '/' ? `${origin}/desktop` : href);
    return this.#userManager.signinRedirect();
  }

  logout() {
    localStorage.setItem('afterSignOutRedirect', window.location.href);
    return this.#userManager.signoutRedirect();
  }

  init = async () => {
    runInAction(() => {
      this.fetchState = 'pending';
    });
    // await this.querySessionStatus();
    await this.getUser();
    runInAction(() => {
      this.fetchState = 'done';
    });
  };

  setUser = async (user: User | 'anonymous' | null) => {
    this.user = user;
    if (this.user !== null && this.user !== 'anonymous' && this.user.profile.idp !== 'Esia') {
      await this.accessMatrix.getAccessMatrix();
      return;
    }
    if (this.user !== null && this.user !== 'anonymous' && this.user.profile.idp === 'Esia') {
      runInAction(() => {
        this.accessMatrix.accessMatrix.menu_account_page = true;
      });
    }
    this.accessMatrix.setDone();
  };

  getUser = async () => {
    const user = await this.#userManager.getUser();
    await this.setUser(user ? user : 'anonymous');

    if (user && user.profile.idp !== 'Esia') {
      await this.getAccountInfo();
    }
  };

  getAccountInfo = async () => {
    const account = await fetchProfile();
    if (account) {
      runInAction(() => {
        this.account = account;
      });
    }
  };

  getPlanningAccess = async () => {
    await this.planningAccess.getUserAccess();
  };

  get systemRoles(): number[] {
    if (!this.account) return [];

    return (
      this.account?.fields?.find((field) => field.code === 'ROLE_OF_THE_SYSTEM')?.value?.value as FieldValue[]
    )?.map((role) => Number(role?.code));
  }

  get userRolesMap() {
    if (!this.account) return [];

    const rolesList = this.account?.fields?.find((field) => field.code === 'ROLE_OF_THE_SYSTEM');

    if (!rolesList || !rolesList.value || !rolesList.value.value || !Array.isArray(rolesList.value.value)) return [];

    return rolesList.value.value.map((r) => ({
      code: r.code as string,
      id: r.id as number,
      text: r.text as string,
    }));
  }

  get userOrganizationsMap() {
    if (!this.account) return [];

    const orgsList = this.account?.fields?.find((field) => field.code === 'ORGANIZATION');

    if (!orgsList || !orgsList.value || !orgsList.value.value || !Array.isArray(orgsList.value.value)) return [];

    return orgsList.value.value.map((r) => ({ id: r.id as number, text: r.text as string }));
  }

  get userRolesAndOrganizations() {
    return {
      organizations: this.userOrganizationsMap,
      roles: this.userRolesMap,
    };
  }

  renewToken = async () => {
    return this.#userManager
      .signinSilent()
      .then((user) =>
        action(() => {
          this.setUser(user ? user : 'anonymous');
        })
      )
      .catch((reason) => Promise.reject(reason));
  };

  // querySessionStatus = async () => {
  //   const user = await this.#userManager.getUser();
  //   if (user !== null) {
  //     await this.#userManager
  //       .querySessionStatus()
  //       .then((status) => {
  //       })
  //       .catch(async (e) => {
  //         if (e.error === 'login_required') {
  //           await this.#userManager.removeUser();
  //           this.setUser('anonymous');
  //         }
  //       });
  //   }
  // };

  removeUser = async () => {
    await this.#userManager.removeUser();
  };
}

const userStoreInstance = new UserStore();

if (import.meta.env.DEV) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['userStoreInstance'] = userStoreInstance;
}

export { UserStore, userStoreInstance };
