import type { ExpandedState } from '@tanstack/react-table';

export const pluralize = ({ count, one, few, many }: { count: number; one: string; few: string; many: string }) => {
  // дорога дороги дорог
  // дорогу дороги дорог
  const n = Math.abs(count) % 100;

  if (n % 100 >= 11 && n % 100 <= 14) return many;

  switch (n % 10) {
    case 1:
      return one;
    case 2:
    case 3:
    case 4:
      return few;
    default:
      return many;
  }
};

export const formatNumber = (n: number, maximumFractionDigits?: number) =>
  new Intl.NumberFormat('ru-RU', { maximumFractionDigits }).format(n);

export const formatDecimalPoints = (
  n: number,
  maximumFractionDigits: number,
  minimumFractionDigits: number | undefined = 0
) => new Intl.NumberFormat('ru-RU', { maximumFractionDigits, minimumFractionDigits }).format(n);

export const formatThousands = (n: number) => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 3 }).format(n);

export const formatPercent = (n: number, total?: number, isRound?: boolean) => {
  const count = total ? (n * 100) / total / 100 : n;
  return new Intl.NumberFormat('ru-RU', {
    style: 'percent',
    minimumFractionDigits: isRound ? 0 : 2,
    maximumFractionDigits: isRound ? 0 : 2,
  }).format(isRound ? Math.round(count * 100) / 100 : count);
};

export const formatKilometers = (n: number) =>
  new Intl.NumberFormat('ru-RU', { style: 'unit', unit: 'kilometer', minimumFractionDigits: 2 }).format(n);

export const roadValue2Icon = {
  1: 'federal',
  2: 'regional',
  3: 'local',
};
export const formatDate = (n: string) => new Intl.DateTimeFormat('ru-RU').format(new Date(n));
export const formatDateTime = (n: string) => {
  const date = new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(n));
  const time = new Intl.DateTimeFormat('ru-RU', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }).format(new Date(n));

  return `${date} в ${time}`;
};

export const downloadBlobFile = (blob: Blob, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('display', 'none');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  if (fileName) a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const setFilenameWithDateAndXlsx = (filename: string) => `${filename} ${new Date().toLocaleString()}.xlsx`;
export const setFilenameWithDateAndCsv = (filename: string) => `${filename} ${new Date().toLocaleString()}.csv`;
export const setFilenameWithDateAndPdf = (filename: string) => `${filename} ${new Date().toLocaleString()}.pdf`;

export const isObject = (value: unknown) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const numRange = (start: number, end: number): number[] => {
  if (start === end) return [start];
  return [start, ...numRange(start + 1, end)];
};

export const getDecimalNumSize = (n: number) => {
  if (n.toString().includes('.')) {
    const res = n.toString().split('.').pop()?.length;
    return res ? res : 0;
  }
  return 0;
};

export const yearsInterval = (years: number[]) => {
  return years ? (years?.length && years?.length > 1 ? `${years[0]}-${years.at(-1)}` : years[0]) : null;
};

export const sliceIntoChunks = (arr: unknown[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const formatBytes = (bytes: number, decimals = 0) => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return new Intl.NumberFormat('ru-RU', { style: 'unit', unit: units[i] }).format(
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  );
};

/* eslint-disable no-control-regex */
export function sanitizeFilename(filename: string) {
  return filename.replace(/[<>:."/\\|?*\x00-\x1F]/g, '');
}

export function clearLocalStorage() {
  const names = ['messages', 'users', 'accidents'];
  names.forEach((name) => localStorage.removeItem(`filterSettings__${name}`));
}

export function updateExpanded(obj: ExpandedState, key: string) {
  if (typeof obj !== 'boolean') {
    if (key in obj) {
      // Если ключ уже есть в объекте, удаляем его
      delete obj[key];
      return obj;
    } else {
      // Если ключ новый, добавляем его с значением true
      return { ...obj, [key]: true };
    }
  }
}
