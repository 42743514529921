import { makeAutoObservable, action, runInAction } from 'mobx';

import { AccessLevel, AccessLevelResponse, AccessMatrix, AccessMatrixKey } from '../types';
import { fetchAccessMatrix, fetchAccessToEndpoint } from '../api';
import { defaultAccessMatrix } from '../defaultAccessMatrix';

import type { UserStore } from '@/features/Auth/store';
import type { FieldValue } from '@/features/Auth/api';
import featureFlagsStoreInstance from '@/features/FeatureFlags/store';

class AccessMatrixStore {
  #userStore: UserStore;
  accessMatrix: AccessMatrix = defaultAccessMatrix;
  fetchState: 'pending' | 'done' | 'error' = 'pending';

  constructor(userStoreInstance: UserStore) {
    this.#userStore = userStoreInstance;
    makeAutoObservable(this);
  }

  doesHaveAccess = (matrixKey: AccessMatrixKey): boolean => {
    if (matrixKey === 'menu_events') {
      return (
        this.#userStore.planningAccess.userRoles.length > 0 ||
        this.#userStore.planningAccess.userRegionalRoles.length > 0
      );
    }
    if (matrixKey === 'menu_events_federal') {
      return this.#userStore.planningAccess.userRoles.length > 0;
    }
    if (matrixKey === 'menu_events_regional') {
      return this.#userStore.planningAccess.userRegionalRoles.length > 0;
    }
    if (matrixKey === 'menu_dashboard_fku') {
      const account = this.#userStore.account;
      if (!account) return false;

      const roles = account.fields.find((el) => el.code === 'ROLE_OF_THE_SYSTEM');
      if (!roles) return false;

      const isAdmin =
        (roles.value?.value as FieldValue[]).find((role) => ['1', '2'].includes(role.code || '')) !== undefined;

      return isAdmin;
    }
    return this.accessMatrix?.[matrixKey] || false;
  };

  doesHaveAccessAtLeastOne = (keys: AccessMatrixKey[]): boolean => {
    return keys.some((matrixKey) => this.doesHaveAccess(matrixKey));
  };

  doesHaveAccessToEndpoint = async (endpoint: string): Promise<AccessLevelResponse> => {
    if (!this.#userStore.isSignedIn) {
      return new Promise((resolve) => resolve({ accessLevel: AccessLevel.Read }));
    }

    return fetchAccessToEndpoint(endpoint).then(
      (data) => data,
      () => ({ accessLevel: AccessLevel.Read })
    );
  };

  getAccessMatrix = async () => {
    this.fetchState = 'pending';

    const accessMatrix = await fetchAccessMatrix();

    if (!accessMatrix) {
      this.fetchState = 'error';
    }

    await this.getAccessForPlanning();

    runInAction(() => {
      accessMatrix.menu_events = !!this.#userStore.planningAccess.userRoles.length;

      this.accessMatrix = accessMatrix;
      this.accessMatrix.menu_desktop = true;
      this.accessMatrix.menu_notifications = true;
      this.accessMatrix.menu_account_page = true;
      this.accessMatrix.menu_newsletter = true;
      this.accessMatrix.menu_administration = true; // @TODO: добавить проверку, что если дочерние недоступны, то и сам пункт меню недоступен
    });
  };

  getAccessForPlanning = async () => {
    if (Object.keys(featureFlagsStoreInstance.features).length === 0) {
      setTimeout(this.getAccessForPlanning, 500);
      return;
    }

    if (featureFlagsStoreInstance.features['isPlanningEnabled']) {
      this.#userStore.planningAccess.getUserAccess().then(
        action(() => {
          const planningRoles = this.#userStore.planningAccess.userRoles;
          if (planningRoles.length) {
            this.accessMatrix.menu_events = true;
          }
          this.fetchState = 'done';
        }),
        action(() => {
          this.fetchState = 'error';
        })
      );
      return;
    }

    runInAction(() => {
      this.fetchState = 'done';
    });
  };

  // TODO: internal state leak, need to make model SOLID-friendly
  setDone = () => {
    this.fetchState = 'done';
  };
}

export { AccessMatrixStore };
